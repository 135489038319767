<template>
  <div>

    <salaries-view

        @reloadSalaries="fetchSalaries2($event)"
    />

  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'

import SalariesView from '../../../components/views/SalariesView.vue'
import useAPI from '../../../utils/useAPI'

export default {
  components: {
    SalariesView
  },
  props: {},
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const { fetchSalaries2 } = useAPI()

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    fetchSalaries2()

    return {
      // Components

      // Data

      // Computed

      // Methods
      fetchSalaries2,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>